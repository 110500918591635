import { PostCard } from '../components/PostCard';
import { Video } from '../components/Video';

const components = {
    // eslint-disable-next-line react/display-name
    PostCard,
    Video,
};

export default components;
